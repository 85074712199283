import React, { Component } from "react";
import QRCode from "react-qr-code";
class SharethisComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      url: this.props.url,
    };
  }

  render() {
    return (
      <QRCode
        size={256}
        style={{
          height: "250",
          maxWidth: "100%",
          width: "100%",
        }}
        value={this.state.url}
        viewBox={`0 0 256 256`}
      />
    );
  }
}

export default SharethisComponent;
